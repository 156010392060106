import React, { Component, Fragment } from "react";
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import Carrusel2 from "../components/Carrusel2";
import Filtros from "../components/Filtros";
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";


class CarruselHome extends Component {

  constructor(props) {
    super(props)

    this.state = {
      categorias: [],
      current_cat: this.props.searchstate.search.current_cat,
      child_cat: this.props.searchstate.search.child_cat,
      display: false
    }

    this.handleClick = this.handleClick.bind(this);
    this.childClick = this.childClick.bind(this);
  }

  handleClick(catego, child = 'loran') {

    this.props.defineInicio(catego, child);

      if (child === false) {
        this.setState({ current_cat: catego });
      } else {
        this.setState({ current_cat: catego, child_cat: child });
      }

  }

  childClick(child) {
    this.setState({ child_cat: child });
    this.props.defineInicio(this.state.current_cat, child);
  }

  peticionAxios() {

    this.setState({ display: false })
    const url = process.env.REACT_APP_URL + 'listar_litems_por_categoria.php';
    let formData = new FormData();
    formData.append('categoria', this.state.current_cat)
    axios.get(url + '?categoria=' + this.state.current_cat + '&plan=' + this.state.child_cat
    ).then(response => response.data)
      .then((data) => {
        this.setState({ categorias: data, display: true })

      })
  }

  componentDidMount() {
    //this.setState({ current_cat: this.props.searchstate.search.current_cat, child_cat: this.props.searchstate.search.child_cat });
    this.peticionAxios();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.current_cat !== this.state.current_cat || prevState.child_cat !== this.state.child_cat) {
      this.peticionAxios();
    }
  }

  render() {
    var vuelta = 0;
    var cat = this.props.searchstate.search.current_cat;
    var child = this.props.searchstate.search.child_cat;
    return (
      (child)?(
      <div className="carruselhome">
        <div className="row">
          <div className="col">
            <nav className="navbar categorias navbar-expand-lg navbar-light">
              <div className="container-fluid d-flex justify-content-center">
                <ul className="navbar-nav ">
                  <li key="carr5" className="nav-item">
                    <button className={cx('nav-link', { 'nav-link activo': cat === 5 })} onClick={() => this.handleClick(5)}>Edades</button>
                  </li>
                  <li key="carr1" className="nav-item">
                    <button className={cx('nav-link', { 'nav-link activo': cat === 1 })} onClick={() => this.handleClick(1)}>Colecciones</button>
                  </li>
                  <li key="carr8" className="nav-item">
                    <button className={cx('nav-link', { 'nav-link activo': cat === 8 })} onClick={() => this.handleClick(8)}>Planes Lectores</button>
                  </li>
                  <li key="carrebooks" className="nav-item">
                    <button className={cx('nav-link', { 'nav-link activo': cat === 'ebooks' })} onClick={() => this.handleClick('ebooks')}>Libros Electrónicos</button>
                  </li>
                  <li key="carrepeda" className="nav-item">
                    <button className={cx('nav-link', { 'nav-link activo': cat === 'pedagogicos' })} onClick={() => this.handleClick('pedagogicos')}>Libros Pedagógicos</button>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        {cat === 8 ? (

          <Fragment>
            <div className="row fondo_gris">
              <div className="col d-flex justify-content-center">

                <button type="button" onClick={() => this.childClick('loran')} className={((child === 'loran') && ('activo')) + " botonplanes d-flex flex-column justify-content-center"}><img srcSet={"/img/loran.png 1x, /img/loran@2x.png 2x"} />Loran</button>

                <button type="button" onClick={() => this.childClick('trotamundos')} className={((child === 'trotamundos') && ('activo')) + " botonplanes d-flex flex-column justify-content-center"}><img srcSet="/img/trotamundos.png 1x, /img/trotamundos@2x.png 2x" />Trotamundos</button>

              </div>
            </div>
            </Fragment>
            ):(null)}

            <Filtros />

            {cat === 8 ? (
              <Fragment>
            {child === 'loran' ? (
              <div className="row fondo_loran">
                <div className="col cabezaplanes">
                  <h1>Loran</h1>
                  <p><strong>Un plan lector que ayuda a comprender mejor las lecturas.</strong></p>
                  <p>Desarrolla las habilidades lectoras de tus alumnos de preescolar, primaria y secundaria con el apoyo de su plataforma digital, en el cual podrás tener actividades interactivas sobre el libro, que, además, podrás leer de manera digital a cualquier lado con ayuda de un dispositivo móvil.</p><p><a href="https://webapp.grupo-sm.com.mx/uploads/pdf/loran.pdf" target="_blank" className="descargaPDF">Conoce más</a></p>
                  <p><a href="https://login.smaprendizaje.com/?origen=mexico" target="_blank" className="descargaPDF">Entra en Loran</a></p>
                </div>
              </div>
            ) : (
              <div className="row fondo_trotamundos">
                <div className="col cabezaplanes">
                  <h1>Trotamundos Lector</h1>
                  <p><strong>Un nuevo plan lector para primaria y secundaria.</strong></p>
                  <p>Consigue que tus alumnos tengan un avance gradual, integral, articulado y placentero; sólo debes seleccionar mínimo 3 títulos de entre 15 propuestas que tenemos para ti por cada grado escolar, y a lo largo del curso podrás ir evaluando a tus alumnos y observando su progreso.</p><p><a href="https://webapp.grupo-sm.com.mx/uploads/pdf/trotamundos.pdf" target="_blank" className="descargaPDF">Conoce más</a></p>
                </div>
              </div>
            )}
          </Fragment>
        ) : (null)}
        <CSSTransition
          in={this.state.display}
          timeout={2000}
          classNames="fade"
          unmountOnExit
        ><div>
            {this.state.categorias.map((categoria) => (

              categoria.carriles.map((carril) => (
                <Fragment key={carril.id}>
                  {cat === 8 ? (
                    <Carrusel2 slugcat={categoria.slugcat} carril={carril} categoria={this.state.current_cat} plan={this.state.child_cat} />
                  ) : (
                    <Carrusel2 slugcat={categoria.slugcat} carril={carril} categoria={this.state.current_cat} plan={this.state.child_cat} />
                  )}



                  {(() => {
                    vuelta++;
                    if (vuelta === 2) {
                      return (
                        <div>Érror</div>

                      )
                    }
                  })}
                </Fragment>

              ))

            ))}
          </div>
        </CSSTransition>


      </div>):(null)
    );
  }


}

const mapStateToProps = state => ({
  searchstate: state
})
const mapDispatchToProps = (dispatch) => {
  return {
    defineInicio: (current_cat, child_cat) => dispatch({ type: 'MENU_INICIO', current_cat: current_cat, child_cat: child_cat }),
    dispatch,
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarruselHome));